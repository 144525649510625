
.principal-proyects {
  margin-top: 250px;
  margin-bottom: 100px;
  text-align: center;
}

.article-grid-proyects {
  display: grid;
  width: 95%;
  margin: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 350px;
  gap: 15px;
  margin-bottom: 100px;
}


.images-proyects {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: 0.5s;
  position: relative;
}

.image-container{ 
  overflow: hidden;
  transition: 0.6s ease-in-out;

}


.image-container:hover img{
  /* filter: grayscale(100%); */
  -webkit-transform:scale(1.3);
  transform:scale(1.3);
  transition-duration: 0.8s;

}


.zoom-gallery {
  position: fixed;
  z-index: 10;
  /* font-weight: 100; */
  background-color: rgba(245, 245, 245, 0.947);
  width: 100vw;
  height: 100vh;
  display: flex;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  justify-content: space-evenly;
  align-content: center;
}

.zoom-gallery img {
  transition: 1s all ease-in-out;
  width: 75vh;
  height: 50vw;
  align-self: center;
  object-fit: cover;
  transition: all 0.8s ease-in-out;
}

.zoom-gallery .arrow {
  align-self: center;
  height: 40px;
  width: 40px;
  color: rgb(26, 25, 25);
  cursor: pointer;

}
.zoom-gallery-principal {
  display: flex;
  justify-content: space-around;
  width: 60%;
}
.zoom-gallery-text {
  align-self: center;
  color: rgb(26, 25, 25);
  width: 30%;
}
.zoom-gallery-text p {
  font-weight: 400;
  color: rgb(26, 25, 25);
  margin: 0;
}

.close {
  position: absolute;
  right: 8%;  
  top: 5%;
  color: rgb(26, 25, 25);
  font-size: 35px;
  cursor: pointer;
}

.shows-gallery {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  top: 0;
}

@media only screen and (max-width: 642px) {
  .zoom-gallery {
    flex-direction: column;
    justify-content: center;
  }
  .zoom-gallery-principal {
    width: 100%;
  }
  .zoom-gallery-principal img {
    width: 70%;
    height: 50vh;
  }
  .zoom-gallery-text {
    width: 70%;
  }
}

@media only screen and (max-width: 425px) {
  .article-grid-proyects {
    grid-template-columns: repeat(2,1fr);
  }
  .zoom-gallery img {
    width: 70vw;
    height: 60vh;
  }


}
@media only screen and (max-width: 400px) {
  .article-grid-proyects {
    grid-template-columns: 1fr;
  }
}
