.about-principal{
  margin-top: 220px;
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
}

.about-text {
  width: 60%;
  margin: auto;
  text-align: center;
}
.about-principal h1 {
  font-size: 2.5rem;
  color: #1e1e1e;
}

.about-text p {
  line-height: 28px;
  font-size: 17px;
  margin-bottom: 60px;
}
.about-text h2 {
  font-size: 2rem;
}
.about-principal-img {
  height: 60vh;
  width: 100vw;
  background-image: url(../source/about-principal-img.JPG);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-grid-img  {
  width: 100%;
  height: 100% ;
  object-fit: cover;
}


.about-grid {
  display: grid;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2,50vh);
  margin-bottom: 100px;
}

.about-grid-title {
  margin:auto;
  text-align: center;
  background-color: rgb(212, 211, 211);
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.about-grid-title h4 {
  position: relative;
  top: 45%;
}

@media only screen and (max-width: 642px) {
  .about-text {
    width: 80%;
  }
  .about-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 60vh;
  }
  
}
@media only screen and (max-width: 400px) {
  .about-grid {
    font-size: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: 60vh;
    grid-auto-rows: 60vh;
  }
  .about-text h2 {
    font-size: 1.8rem;
  }
  .about-principal h1 {
    font-size: 2rem;
    width: 100%;
  }
  .about-principal-img {
    background-attachment: unset;
  }
}


