nav {
  display: flex;
  width: 90%;
  padding-left: 7%;
  padding-right: 7%;
  position: fixed;
  background-color: whitesmoke;
  top: 0; 
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  height: 19vh;
}
nav img {
  width: 18%;
  height: 80%;
  margin-top: 10px;
}

.navbar-left {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
}

li {
  display: inline;
  text-decoration: none;
}
a, p{
  text-decoration: none;
  color: rgb(86, 82, 82);
  margin: 10px;
  font-weight: 100;
}

a:hover {
  color: rgb(212 0 0);
  transition: 0.5s;
}

.symbol-menu {
  position: absolute;
  display: none;
  font-size: 30px;
  top: 30px;
  right: 5px;
  color: rgb(86, 82, 82);
  z-index: 10;
}

@media only screen and (max-width: 900px) {
  nav {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 770px) {
  nav {
    margin-top: 20px;
    padding: 0%;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    height: 30px;
  }
  nav img {
    width: 180px;
    height: 100px;

  }
  .navbar-left {
    width: 95%;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  ul {
    padding: 0;
  }  
  nav img {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 642px) {

  ul {
    position: fixed;
    top: -100%;
    left: 0;
    height: 95vh;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: whitesmoke;
    margin: 0;
    transition:all 250ms ease-out;
    transition: 0.8 ease-in-out;
  }
  .symbol-menu {
    display: block;
  }
  li{
    display: inline;
    font-size: 25px;
    font-weight: 500;
    margin: auto;
  }
  .shows {
    top: 0%;
    transition: 0.8s ease-in-out;
    opacity: 1;
  }

}
