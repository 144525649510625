  .principal-article {
    /* background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url('https://images.pexels.com/photos/2663254/pexels-photo-2663254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url('../source/principal-article-img.JPG');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 82vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 140px;
    margin-left: 8%;
    margin-right: 6.7%; 
  }

   .principal-article h1 {
    font-size: 4rem;
    width: 630px;
    margin-left: 60px;
    color: whitesmoke;
  }

  .principal-article-link, .general-info-article-link {
    color: rgb(230, 230, 230);
    border: 1px solid;
    text-align: center;
    width: 160px;
    padding: 7px;
    margin-left: 60px;

  }
  .general-info-article-link {
    background-color: #1e1e1e;
    margin-left: 20%;
  }

  .general-info-article {
    display: flex;
    height: 80vh;
    /* margin-bottom: 500px; */
    margin-top: 40PX;
    margin-left: 8%;
    margin-right: 6.7%; 
  }

  .general-info-article-img {
    width: 50%;
    background-image: url('../source/general-info-img.JPG');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .general-info-article-text {
   /*                     CAMBIAR ALTURAS, SE VEN RARAS EN UNA TABLET                               */
   
   
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    color: #1e1e1e;

  }

  .general-info-article-text h2 {
    margin-left: 20%;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    font-size: 45px;
    color: #1e1e1e;
    font-weight: 500;
    width: 50%;
  }

  .general-info-article-text p {
    margin-left: 20%;
    width: 60%;
    font-weight: 100;
  }

  .Our-services {
    padding-top: 60px;
    text-align: center;
    background-color: rgb(230, 230, 230);
    width: 100vw;
    height: auto;
    padding-bottom: 50px;
  }
  .Our-services-title {
    font-size: 3rem;
    font-weight: 500;
    color: #1e1e1e;
  }
  .Our-services-images {
    width: 80%;
    margin: auto;
    display: grid;
    place-content: center;
    align-items: center;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-template-rows: 90vh;
    column-gap: 20px;
    margin-bottom: 80px;
  }
  span {
  height: 100%;
  color: whitesmoke;  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  
  }
  span h3 {
    margin-top: 90%;
    font-size: 20px;
  
  }

 .Our-services-img1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ,url(../source/our-services-1.jpeg);
  
}
.Our-services-img2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ,url(../source/our-services-2.jpeg);
}
.Our-services-img3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ,url(../source/our-services-3.jpeg);
}
.Our-services-link {
  margin: 0;
  color: rgb (230, 230, 230);
  padding:  10px;
}
  @media only screen and (max-width: 900px) {
    .principal-article {
      margin-top: 130px;
    }
  }

  @media only screen and (max-width: 770px) {
    body, html {
      max-width: 100% !important;
    }
    .principal-article{
      align-items: center;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      margin-top: 180px;
    }
    .principal-article h1 {
      text-align: center;
      font-size: 3rem;
      margin-left: 0;

    }
    .principal-article-link {
      
      margin-left: 0;
      margin-right: 0;
    }
    .general-info-article {
    flex-direction: column-reverse;
    align-items: center;
    justify-content:center ;
    margin-left: 0;
    height: auto;
    margin-right: 0;
    }
    .general-info-article-img {
      width: 100%;
    }
    .general-info-article-img {
      height: 70vh;
      background-size: cover;

    }
    .general-info-article-text {
      width: 80%;
    }

    .general-info-article-text h2,  .general-info-article-text p {
      width: 100%;
      margin: 0;
      margin-top: 30px;
      text-align: center;
    }
    .general-info-article-link {
      margin-left: 0;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;
      align-self: center;
    }
   
    .Our-services-images{
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      column-gap: 0;
      width: 100%;
    }
    
  }
  @media only screen and (max-width: 642px) {
    .principal-article {
      height: 50vh;
      margin-top: 150px;
      background-attachment: unset;
    }
   .principal-article h1  {
      font-size: 1.9rem;
      width: 80%;
      align-self: center;
      justify-self: center;
      margin-left: 0;
      margin: auto;
    }
    .general-info-article-text h2 {
      font-size: 30px;
      width: 90%;
      margin: auto;
    }
    .general-info-article-text p {
      width: 90%;
      margin: auto;
      margin-top: 30px;
    }
    .general-info-article-img {
      background-attachment: unset;
      background-size: cover;
    }
    .Our-services-title{
      font-size: 2.5rem;
    }
  }